<template>
    <div class="login-container">
        <div class="login-wrapper">
            <div class="login-header">
                <div class="avatar">
                    <div class="avatar-image">
                        <img src="/img/icons/logo-info.png" alt="">
                    </div>
                    <div class="avatar-name">Informitalia</div>
                </div>
                <div class="title">
                    Login
                </div>
            </div>
            <div class="login-fields">
                <FormInput labelText="Username" v-model:value="userData.userName" placeholder="Username" @login="login"/>
                <FormInput :type="showHidePW" labelText="Password" v-model:value="userData.userPassword" placeholder="Password" icon="visibility" :iconRight="true" @showPassword="showPassword" @login="login" />
                <div v-if="error" class="error">
                    Username e/o password errati
                </div>
            </div>
            <div class="login-footer">
                <input type="submit" class="submit" @click="login()" value="Accedi">
                <div class="recover-password">
                    <router-link :to="'/reset-password'">Primo accesso o hai dimenticato la password?</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import FormInput from '../components/tools/FormInput.vue';
import Login from '../services/loginService';
import dayjs from 'dayjs';
import ShopService from '../services/shopService';
import WorkerService from '../services/workerService';
import GroupService from '../services/groupService';
import ScheduleService from '../services/scheduleService';

</script>

<script>

export default {
    name : 'app-login',
    components : {
        FormInput
    },
    data(){
        return{
            userData : {
                userName : null,
                userPassword : null
            },
            error : false,
            showHidePW: 'password'
        }
    },
    computed : {
        data(){
            var data = new FormData();
            data.append('email', this.userData.userName)
            data.append('password', this.userData.userPassword)

            return data
        }
    },
    methods : {
        async login(){
            const isAuthenticated = await Login.userLogin(this.data)
            console.log(isAuthenticated)
            
            if(isAuthenticated.success == true){

                localStorage.setItem('user_id', isAuthenticated.user_id)
                localStorage.setItem('expiry', dayjs().add(16, 'hours').unix())
                localStorage.setItem('shopsList', JSON.stringify(await ShopService.getShops(localStorage.getItem('token'))))
                localStorage.setItem('workersList', JSON.stringify(await WorkerService.getWorkers(localStorage.getItem('token'))))
                localStorage.setItem('groupsList', JSON.stringify(await GroupService.getGroups(localStorage.getItem('token'))))
                localStorage.setItem('schedulesList', JSON.stringify(await ScheduleService.getAllSchedules(localStorage.getItem('token'))))
                this.$router.push('/')
            }else{

                this.error = true
            }
        },
        showPassword(type){
            if(type == 'password'){
                this.showHidePW = 'text'
            }else{
                this.showHidePW = 'password'
            }
        }
    }
}

</script>