<template>
    <div class="main">  
        <Heading v-if="viewPeriod || calendar" @back="back" :title="(calendar) ? 'Calendario' : 'Turno'" /> 
        <div v-if="!viewPeriod && !calendar" class="dashboard-container">
            <div class="info-container">
                <div class="info days-off">
                    <div class="title">Riposo</div>
                    <div class="icon-container">
                        <svg class="iconClass">
                            <use xlink:href="#dark-mode"></use>
                        </svg>
                    </div>
                    <div class="text">
                        {{ dayOff }}
                    </div>
                </div>
                <div class="info hours">
                    <div class="title">Monte ore</div>
                    <div class="icon-container">
                        <svg class="iconClass">
                            <use xlink:href="#schedule"></use>
                        </svg>
                    </div>
                    <div class="text">
                        {{ `${(totHours !== null) ? totHours : '-'} / ${(worker.num_hours) ? `${worker.num_hours}h` : '-'}` }}
                    </div>
                </div>
            </div>
            <router-link :to="'/turni-programmati'">
                <div class="calendar">
                    <div class="icon-container">
                        <svg class="iconClass">
                            <use xlink:href="#calendar-month"></use>
                        </svg>
                    </div>
                    <div class="text">
                        <p>Calendario</p>
                        <p>Scopri i turni programmati</p>
                    </div>
                </div>
            </router-link>
            <div v-if="shift" class="current-shift">
                <SingleShift :day="dayjs().day(1).format('YYYY-MM-DD')" :resource="worker.full_name" :morning="shift.morning" :afternoon="shift.afternoon" :evening="shift.evening" @selectPeriod="selectPeriod" />
            </div>
        </div>
        <ShiftView v-if="viewPeriod" :period="period" :day="shift.date" @back="back"/>
    </div>
</template>

<script setup>

import DaysOffService from '../services/daysOffService';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import dayjs from 'dayjs';
import Heading from '@/components/scheduler/Heading.vue';
import { userDaysOff, singleShift, hourConverter } from '../dataService';
import { getWorker } from '../getters';
import SingleShift from '../components/scheduler/SingleShift.vue';
import shiftsService from '../services/shiftsService';
import ShiftView from './ShiftView.vue';

dayjs.extend(dayOfYear)
require('dayjs/locale/it')
const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

</script>

<script>

export default {
    name: 'dashboard-informitalia',
    components: {
        SingleShift,
        ShiftView,
        // Calendar,
        Heading
    },

    data() {
        return {
            options: {
                start: dayjs().day(1).format('YYYY-MM-DD')
            },
            calendar: false,
            daysOff: false,
            groupId: null,
            isPanoramic: false,
            schedule_id: null,
            schedulesList: [],
            shift: null,
            shopVerify: false,
            shops: true,
            totHours: null,
            update: false,
            verify: false,
            period: null,
            viewPeriod: false
        }
    },
    methods: {
        panoramic(bool) {
            this.isPanoramic = bool

        },
        workers() {
            this.shops = false
        },
        shop() {
            this.shops = true
        },
        verifyShops(){
            this.shopVerify = true
        },
        close(){
            this.verify = false
            this.shopVerify = false
            this.daysOff = false
        },
        selectPeriod(period){
            this.period = period
            this.viewPeriod = true
        },
        back(bool){
            this.viewPeriod = bool
            this.calendar= bool
        }
    },
    computed : {
        worker(){
            var user_id = localStorage.getItem('user_id')
            return getWorker(user_id)
        },
        dayOff(){
            var result = '';
            Array.from(this.daysOff).map((day, index) => {
                if(index < this.daysOff.length - 1){
                    result += `${day} / `;
                }else{
                    result += day;
                }
            })

            return result
        }
    },  
    watch: {

    },
    async mounted() {
        this.daysOff = userDaysOff(await DaysOffService.getUserDaysOff(localStorage.getItem('token'), dayjs().day(6).year(), dayjs().week(), localStorage.getItem('user_id')), dayjs().day(1).format('YYYY-MM-DD'))
        var shift = await singleShift(dayjs().format('DD-MM-YYYY'), dayjs().day(6).year(), dayjs().week(), localStorage.getItem('user_id'));
        console.log('shift dashboard: ', shift)
        this.shift = shift[0]
        var totHours = await shiftsService.getSingleWorkerWeekTotHours(localStorage.getItem('token'), dayjs().day(6).year(), dayjs().week(), localStorage.getItem('user_id'))
        Array.from(totHours).map(hours => {
            if(localStorage.getItem('user_id') == hours.user_id){
                this.totHours = hours.tot_hours.toFixed(2)
            }
        })

        this.totHours = hourConverter(this.totHours)
    }
}

</script>