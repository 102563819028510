<template>
    <div class="legend-container">
        <div class="item">
            <div class="status success"></div>
            <div class="text">Svolto</div>
        </div>
        <div class="item">
            <div class="status warning"></div>
            <div class="text">Parziale</div>
        </div>
        <div class="item">
            <div class="status invalid"></div>
            <div class="text">Incompleto</div>
        </div>
        <div class="item">
            <div class="status error"></div>
            <div class="text">Non svolto</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'calendar-legend'
}

</script>