import { isDayOff, alphabetic } from './dataService'

export const getWorker = (id) => {
    var the_worker;
    var workers = JSON.parse(localStorage.getItem('workersList'))

    Array.from(workers).map((worker) => {
        if(worker.id == id) the_worker = worker
    })

    return the_worker
}

export const getShop = (id) => {
    var the_shop;
    var shops = JSON.parse(localStorage.getItem('shopsList'))

    Array.from(shops).map((shop) => {
        if(shop.id == id) the_shop = shop
    })

    return the_shop
}

export const getShift = (id) => {
    var the_shift;
    var shifts = JSON.parse(localStorage.getItem('shiftsList'))

    Array.from(shifts).map((shift) => {
        if(shift.id == id) the_shift = shift
    })

    return the_shift
}

export const getGroup = (id) => {
    var the_group;
    var groups = JSON.parse(localStorage.getItem('groupsList'))

    Array.from(groups).map((group) => {
        if(group.id == id) the_group = group
    })

    return the_group
}

export const getSchedule = (id) => {
    var the_schedule;
    var schedules = JSON.parse(localStorage.getItem('schedulesList'))

    Array.from(schedules).map((schedule) => {
        if(schedule.id == id) the_schedule = schedule
    })

    return the_schedule
}

export const getAvailableWorkers = (workersList, daysOff, bool, user_id, shiftDate) => {

    const allWorkers = JSON.parse(localStorage.getItem('workersList'));
    var workers = [];
    var selectedWorker;
    var result = [];

    Array.from(workersList).map(async (worker) => {

        if(worker.status !== 'archiviato'){
            workers.push(worker)
        }
    })

    Array.from(allWorkers).map((worker) => {

        if (bool && user_id == worker.id) {

            selectedWorker = worker
            workers.push(selectedWorker)
        }
    })

    var id = [];

    Array.from(workers).map((worker) => {
        Array.from(daysOff).map((list) => {
            if(list.user_id == worker.id && isDayOff(list, shiftDate) == true){
                id.push(worker.id)
            }
        })
    })

    Array.from(workers).map((worker) => {
        if(!id.includes(worker.id)){
            console.log(worker.surname)
            result.push(worker)
        }
    })

    result = result.sort(alphabetic)

    return result
}
