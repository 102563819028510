import axios from "axios"
import dayjs from "dayjs"
import StatusService from "./statusService"
import { alphaShop } from '../dataService'

class Shop {

    constructor() {
        this.url = `${this.endpoint()}/api/v1/shops`
    }

    endpoint = () => {
        
        if(process.env.NODE_ENV == 'production') {
            if(window.location.origin == 'https://informitalia-client.quattrolinee.com') return 'https://informitalia-server.quattrolinee.com';
            else if(window.location.origin == 'https://informitalia-client-test.quattrolinee.com') return 'https://informitalia-server-test.quattrolinee.com';
            else if(window.location.origin == 'https://informitalia-client-test-2.quattrolinee.com' || window.location.origin == 'https://informitalia-app-test.quattrolinee.com') return 'https://informitalia-server-test-2.quattrolinee.com';
            else return 'https://api.informitalia.info';
        }
        else return 'https://informitalia-server-test-2.quattrolinee.com';
    }

    async getShop(token, id){
        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url + '/' + id, config)

        .then(function (response) {

            console.log('SHOP DATA: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false

        })
    }


    async getShops(token) {

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url, config)

        .then(function (response) {

            console.log('SHOPS LIST', response.data.data)
            var result = response.data.data.sort(alphaShop)
            return result

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async getLastShops() {
        const allShops = localStorage.getItem('shopsList')

        var lastShops = []

        Array.from(allShops).map((shop) => {
            lastShops.push(shop)
        })

        for(var index = 0; index < lastShops.length; index++){
            lastShops[index].updated_at = lastShops[index].updated_at.split('/').reverse().join('-')
        }

        lastShops.map((shop, index) => {
            if(index > 0){
                if (dayjs(lastShops[index - 1].updated_at).unix() < dayjs(shop.updated_at).unix()) {
                    lastShops.shift()
                    lastShops.push(lastShops[index - 1])
                }
            }
        })

        return lastShops.slice(0, 3);
    }

    async addShop(token, shopData) {
        
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.post(this.url,shopData, config)

        .then(function (response) {

            console.log('SHOP ADDED: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async deleteShop(token, id){
        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.delete(this.url + '/' + id, config)

        .then(function (response) {

            console.log('DELETED SHOP: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async updateShop(token, shopData, id){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.put(this.url + '/' + id, shopData, config)

        .then(function (response) {

            console.log('UPDATED SHOP: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

}

export default new Shop()