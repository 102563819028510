<template>
	<div class="center stream">
		<qrcode-stream @detect="onDetect"></qrcode-stream>
	</div>
	<Modal v-if="checked_in == true" title="Turno iniziato" text="Scan avvenuto con successo" @redirect="redirect" confirmText="Chiudi"
		success="true" />
	<Modal v-if="checked_in == false" :title="errorMessage" text="Scan non riuscito" :cancel="true" confirmText="Riprova" class="border"
		@redirect="$emit('camera', false)" @cancel="redirect" cancel-text="Chiudi" success="false" />
	<Modal v-if="checked_out == true" title="Turno terminato" text="Scan avvenuto con successo" @redirect="redirect"
		success="true" />
	<Modal v-if="checked_out == false" :title="errorMessage" text="Scan non riuscito" :cancel="true" confirmText="Riprova" class="border"
		@redirect="$emit('camera', false)" @cancel="redirect" cancel-text="Chiudi" success="false" />
	<Modal v-if="tooEarly == true" title="Fine turno anticipato" text="Vuoi terminare il turno in anticipo? Proseguendo il tuo turno verrà conteggiato come parziale."
		class="border" @redirect="checkout" :cancel="true" cancel-text="Chiudi" @cancel="redirect" success="warning" />
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import ShiftsService from "@/services/shiftsService";
import dayjs from "dayjs";
import Modal from "../tools/Modal.vue";
import { totHoursCounter } from "@/dataService";

export default {
	name: "QRreader",
	props: {
		period: {
			default: null,
			type: Number
		}
	},
	components: {
		QrcodeStream,
		Modal
	},
	data() {
		return {
			errorMessage: null,
			result: null,
			checked_in: null,
			checked_out: null,
			tooEarly: false,
			code: null,
			detectedCodes: null
		}
	},
	computed: {},
	emits: ['camera'],
	methods: {
		async onDetect(detectedCodes) {

			this.code = detectedCodes[0].rawValue
			this.detectedCodes = detectedCodes

			if (this.period.check_in == null) {

				var data = {
					code: detectedCodes[0].rawValue,
					check_in: dayjs().format('HH:mm') + ':00'
				}

				const Checkin = await ShiftsService.checkin(localStorage.getItem('token'), this.period.shift_id, data)


				if (Checkin.success !== false) {
					this.checked_in = true
				} else {
					this.checked_in = false
					this.errorMessage = (Checkin.message) ? this.errorMessage = Checkin.message : 'Errore'
				}

			} else if (this.period.check_in !== null && this.period.check_out == null) {

				data = {
					code: detectedCodes[0].rawValue,
					check_out: dayjs().format('HH:mm') + ':00'
				}

				if (totHoursCounter(this.period.time_end, dayjs().format('HH:mm')) < 0) {

					this.tooEarly = true
				} else {
					const Checkout = await ShiftsService.checkout(localStorage.getItem('token'), this.period.shift_id, data)

					if (Checkout.success !== false) {
						this.checked_out = true
					} else {
						this.checked_out = false
						this.errorMessage = (Checkout.message) ? this.errorMessage = Checkout.message : 'Errore'
					}
				}
			}
		},
		redirect() {
			this.$router.go()
		},
		async checkout(){

			this.tooEarly = false

			var data = {
				code: this.code,
				check_out: dayjs().format('HH:mm') + ':00'
			}

			const Checkout = await ShiftsService.checkout(localStorage.getItem('token'), this.period.shift_id, data)

			if (Checkout.success !== false) {
				this.checked_out = true
			} else {
				this.checked_out = false
				this.errorMessage = (Checkout.message) ? this.errorMessage = Checkout.message : 'Errore'
			}
		},
		cancel(){
			this.checked_in = null
			this.checked_out = null
			this.tooEarly = false
		}
	},
	mounted(){
		console.log(totHoursCounter(this.period.time_end, dayjs().format('HH:mm')))
	}
}

</script>

<style scoped></style>
