import axios from "axios"
import StatusService from "./statusService"

class Worker {

    constructor(){
        this.url = `${this.endpoint()}/api/v1/users`
    }

    endpoint = () => {
        
        if(process.env.NODE_ENV == 'production') {
            if(window.location.origin == 'https://informitalia-client.quattrolinee.com') return 'https://informitalia-server.quattrolinee.com';
            else if(window.location.origin == 'https://informitalia-client-test.quattrolinee.com') return 'https://informitalia-server-test.quattrolinee.com';
            else if(window.location.origin == 'https://informitalia-client-test-2.quattrolinee.com' || window.location.origin == 'https://informitalia-app-test.quattrolinee.com') return 'https://informitalia-server-test-2.quattrolinee.com';
            else return 'https://api.informitalia.info';
        }
        else return 'https://informitalia-server-test-2.quattrolinee.com';
    }

    async getWorkers(token){

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json', 
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url, config)
        
        .then(function(response){

            console.log('USERS LIST: ', response.data.data)
            return response.data.data

        })
        
        .catch(function (error){

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async getWorker(token, id){

        const config = {
            headers: {
                'Accept': 'application/json', 
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url + '/' + id, config)
        
        .then(function(response){

            console.log('WORKER: ', response.data.data)
            return response.data.data

        })
        
        .catch(function (error){

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false

        })
    }

    async addWorker(token, workerData) {
        
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.post(this.url, workerData, config)

        .then(function (response) {

            console.log('WORKER ADDED: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async deleteWorker(token, id){
        
        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.delete(this.url + '/' + id, config)

        .then(function (response) {

            console.log('WORKER DELETED: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async updateWorker(token, workerData, id){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.put(this.url + '/' + id, workerData, config)

        .then(function (response) {

            console.log('UPDATED WORKER: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async getInspectors(token){
        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json', 
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url, config)
        
        .then(function(response){

            console.log('INSPECTORS LIST: ', response.data.data)
            return response.data.data

        })
        
        .catch(function (error){

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

}

export default new Worker();