<template>
    <div class="menu" :class="openMenu()">
        <nav class="navbar">
            <router-link :to="'/'" ><div class="app-title">Informitalia</div></router-link>
            <div class="icons">
                <div class="hamburger" @click="(close) ? close = false : close = true">
                    <div class="square">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </nav>
        <div class="menu-items">
            <router-link @click="close = true" to="/">
                Dashboard 
                <svg class="icon icon-arrow-back">
                    <use xlink:href="#arrow-back"></use>
                </svg>
            </router-link>
            <router-link @click="close = true" to="/turni-programmati">
                Turni Programmati
                <svg class="icon icon-arrow-back">
                    <use xlink:href="#arrow-back"></use>
                </svg>
            </router-link>
            <router-link @click="close = true" to="/profilo">
                Profilo
                <svg class="icon icon-arrow-back">
                    <use xlink:href="#arrow-back"></use>
                </svg>
            </router-link>
            <div class="logout" @click="logout()">
                <div class="text">Logout</div>
                <div class="icon-container log-out">
                    <svg class="icon icon-share icon--rotate-90">
                        <use xlink:href="#exit"></use>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<script>

export default {
    name: 'app-menu',
    data() {
        return {
            close: true
        }
    },
    methods: {
        openMenu(){
            if(this.close == true){
                return 'close'
            }else{
                return 'open'
            }
        },
        closeMenu(bool){
            this.close = bool
        },
        logout() {
            localStorage.clear()
            this.$router.go()
        },
    }
}

</script>