<template>
    <div :id="id" class="week-day">{{ formattedDay }}</div>
    <div :id="resource" class="calendar-day" :class="day" ref="shift" @click="$emit('shiftTime', $event.target)">
        <div v-if="morning.time_start !== null && morning.visible == true" class="morning-shift-container" :class="event(morning.worker)">
            <div class="morning-shift">
                <div class="worker">
                    {{ morning.worker }}
                </div>
                <div v-if="morning.time_start !== null" class="shift">
                    <div class="shift-status" :class="shiftStatus(morning.status)"></div>
                    <div class="shift-time">
                        {{ morning.time_start + ' - ' +  morning.time_end }}
                    </div>
                </div>
            </div>
            <div v-if="morning.check_in !== null" class="actual-morning-shift morning-shift">
                <div class="status">{{ status(morning.status) }}</div>
                <div class="actual-time">{{ morning.check_in + ' - ' +  morning.check_out }}</div>
            </div>
        </div>
        <div v-if="afternoon.time_start !== null && afternoon.visible == true" class="afternoon-shift-container" :class="event(afternoon.worker)">
            <div class="afternoon-shift">
                <div class="worker">
                    {{ afternoon.worker }}
                </div>
                <div v-if="afternoon.time_start !== null" class="shift">
                    <div class="shift-status" :class="shiftStatus(afternoon.status)"></div>
                    <div class="shift-time">
                        {{ afternoon.time_start + ' - ' +  afternoon.time_end }}
                    </div>
                </div>
            </div>
            <div v-if="afternoon.check_in !== null" class="actual-afternoon-shift afternoon-shift">
                <div class="status">{{ status(afternoon.status) }}</div>
                <div class="actual-time">{{ afternoon.check_in + ' - ' +  afternoon.check_out }}</div>
            </div>
        </div>
        <div v-if="evening.time_start !== null && evening.visible == true" class="evening-shift-container" :class="event(evening.worker)">
            <div class="evening-shift">
                <div class="worker">
                    {{ evening.worker }}
                </div>
                <div v-if="evening.time_start !== null" class="shift">
                    <div class="shift-status" :class="shiftStatus(evening.status)"></div>
                    <div class="shift-time">
                        {{ evening.time_start + ' - ' +  evening.time_end }}
                    </div>
                </div>
            </div>
            <div v-if="evening.check_in !== null" class="actual-evening-shift evening-shift">
                <div class="status">{{ status(evening.status) }}</div>
                <div class="actual-time">{{ evening.check_in }} - {{ (evening.check_out !== null) ? evening.check_out : '' }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'

export default {
    name: 'Scheduler-Shift',
    props: {
        day: {
            default: null,
            type: String
        },
        resource: {
                default: null,
                type: String
        },
        morning: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            },
            check_in: {
                default: null,
                type: String
            },
            check_out: {
                default: null,
                type: String
            },
            
            status: {
                default: null,
                type: String
            },
        },
        afternoon: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            },
            check_in: {
                default: null,
                type: String
            },
            check_out: {
                default: null,
                type: String
            },
            
            status: {
                default: null,
                type: String
            },
        },
        evening: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            },
            check_in: {
                default: null,
                type: String
            },
            check_out: {
                default: null,
                type: String
            },
            
            status: {
                default: null,
                type: String
            },
        },
        week : {
            default : 'current',
            type : String
        }
    },
    data() {
        return {
            scheduled_days: 0,
        }
    },
    emits: ['shiftTime'],
    methods: {
        event(elem){
            if(elem !== null){
                return ' event';
            }else{
                return '';
            }
        },
        status(string){
            if(string !== null) return string[0].toUpperCase() + string.substring(1, string.length)
            else return ''
        },
        shiftStatus(string){
            if(string !== null){
                if(string == 'svolto') return 'success'
                else if(string == 'non svolto') return 'error'
                else if(string == 'inconcluso') return 'invalid'
                else return 'warning'
            }
        }
    },
    computed : {
        formattedDay(){
            var day = this.day.split('-').reverse().join('-')
            day = dayjs(day).locale('it').format('dddd DD MMMM')
            day = day.substring(0, 1).toUpperCase() + day.substring(1, day.length)
            return day
        },
        id(){
            var day = this.day.split('-').reverse().join('-')
            day = dayjs(day).locale('it').format('DD-MM')
            return day
        }
    },
    mounted() {
        Array.from(document.querySelectorAll('.calendar-day')).forEach(el => {
            this.scheduled_days += el.children.length
        })
    },
}

</script>