<template>
    <div class="modal-wrapper confirm-modal" :class="noButton">
        <div class="edit-shifts-container">
            <div v-if="success !== null" class="result-image">
                <svg v-if="success == 'true'" class="iconClass">
                    <use xlink:href="#good-mood"></use>
                </svg>
                <svg v-if="success == 'warning'" class="iconClass">
                    <use xlink:href="#warning"></use>
                </svg>
                <svg v-if="success == 'false'" class="iconClass">
                    <use xlink:href="#bad-mood"></use>
                </svg>
            </div>
            <div class="heading">
                <div class="title" :class="(error) ? 'error' : ''">
                    {{ title }}
                </div>
            </div>
            <div class="description">
                {{ text }}
            </div>
            <div class="edit-buttons-container">
                <Button v-if="cancel" :text="cancelText" buttonType="errorFull" icon="close" rounded="true" @click="$emit('cancel')" :style="{ 'width' : (cancel) ? '50%' : '100%' }"/>
                <Button :text="confirmText" :buttonType="(error) ? 'errorFull' : 'successFull'" icon="check" rounded="true" @click="$emit('redirect')" :style="{ 'width' : (cancel) ? '50%' : '100%' }"/>
            </div>
        </div>
    </div>
</template>

<script setup>

import Button from './Button.vue';

</script>

<script>

export default {
    name : 'profile-remover',
    props : {
        text : {
            default : null,
            type : String
        },
        toRedirect : {
            default : null,
            type : String
        },
        title : {
            default: null,
            type : String
        },
        cancel : {
            default : false,
            type : Boolean
        },
        noButton : {
            default: '',
            type: String
        },
        success : {
            default: null,
            type: String
        },
        cancelText : {
            default: 'Chiudi',
            type : String
        },
        confirmText : {
            default: 'Prosegui',
            type : String
        }
    },
    methods : {
        redirect(){
            this.$router.push(this.toRedirect)
        }
    }

}

</script>