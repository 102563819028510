<template>
	<div v-if="!camera" class="single-shift-container">
		<div class="single-shift">
			<div class="shift-item day">{{ `Oggi, ${formattedDay}` }}</div>
			<div class="shift-item point">{{ period.worker }}</div>
			<div class="shift-item address">{{ (address(period.id)) ? address(period.id) : 'Nessun indirizzo' }}</div>
			<div class="shift-item start">
				<div>Inizio Turno</div>
				<div class="time">
					{{ period.time_start }}
				</div>
			</div>
			<div class="shift-item end">
				<div>Fine Turno</div>
				<div class="time">
					<div>{{ period.time_end }}</div>
				</div>
			</div>
			<div class="shift-item end">
				<div>Check In</div>
				<div class="time">
					<div v-if="period.check_in !== null" class="status" :class="shiftStatus(period.status)"></div>
					<div>{{ (period.check_in !== null) ? period.check_in : '-' }}</div>
				</div>
			</div>
			<div class="shift-item end">
				<div>Check Out</div>
				<div class="time">
					<div v-if="period.check_out !== null" class="status" :class="shiftStatus(period.status)"></div>
					<div>{{ (period.check_out !== null) ? period.check_out : '-' }}</div>
				</div>
			</div>
			<div class="shift-item scheduled">
				<div>Previsto</div>
				<div>{{ totHours }} h</div>
			</div>
			<div class="shift-item effective">
				<div>Effettuato</div>
				<div>{{ (period.tot_hours_worked !== null) ? `${hourConverter(period.tot_hours_worked)}` : '-' }}</div>
			</div>
		</div>
		<div class="shift-view-footer">
			<div v-if="period.check_in == null && scan == false" class="checkin-message">
				<div>Scannerizza il QR Code per iniziare il turno</div>
			</div>
			<div v-if="scan == true" class="checkin-message">
				<div>Disponibile a partire dalle {{ checkinTime }}</div>
			</div>
			<div v-if="scan == false && period.check_in !== null && period.check_out == null" class="checkout-message">
				<div>Scannerizza il QR Code per terminare il turno</div>
			</div>
			<Button v-if="period.check_in == null" text="Scan QR Code" class="check-in-button" :disabled="scan"
				buttonType="successFull" :rounded="true" icon="qr-code" @click="camera = true" />

			<Button v-if="period.check_in !== null && period.check_out == null" text="Scan QR Code"
				:buttonType="checkout" :rounded="true" icon="qr-code" @click="camera = true" />
		</div>
	</div>
	<QRreader v-if="camera" @camera="cameraOff" :period="period" />
</template>

<script>

import QRreader from '@/components/checkin/QRreader.vue';
import dayjs from 'dayjs';
import { getShop } from '../getters';
import { totHoursCounter, hourConverter, timeConverter } from '../dataService'
import Button from '@/components/tools/Button.vue';

export default {
	name: 'shift-view',
	components: {
		Button,
		QRreader,
	},
	props: {
		period: {
			default: null,
			type: Object
		},
		day: {
			default: null,
			type: String
		}
	},
	data() {
		return {
			camera: false,
			scan: true,
			moment: null,
		}
	},
	methods: {
		address(id) {
			var shop = getShop(id);
			return shop.address
		},
		cameraOff(bool) {
			this.camera = bool
		},
		checkTime(now, start, end) {
			console.log(totHoursCounter(start, now))
			if (totHoursCounter(start, now) > -0.25 && totHoursCounter(end, now) <= 0) {
				return false
			} else {
				return true
			}
		},
		shiftStatus(string) {
			if (string !== null) {
				if (string == 'svolto') return 'success'
				else if (string == 'non svolto') return 'error'
				else if (string == 'inconcluso') return 'invalid'
				else return 'warning'
			}
		},
		hourConverter(string) {
			return hourConverter(string)
		}
	},
	computed: {
		formattedDay() {
			var day = this.day.split('-').reverse().join('-')
			day = dayjs(day).locale('it').format('dddd DD MMMM')
			day = day.substring(0, 1).toUpperCase() + day.substring(1, day.length)
			return day
		},
		totHours() {
			return totHoursCounter(this.period.time_start, this.period.time_end)
		},
		checkinTime() {
			return timeConverter(totHoursCounter('00.15', this.period.time_start))
		},
		checkout(){
			if(totHoursCounter(dayjs().format('HH:mm'), this.period.time_end) > 0){
				return 'warningFull'
			}else{
				return 'successFull'
			}
		}
	},
	mounted() {
		this.moment = dayjs().format('HH:mm')
		this.scan = this.checkTime(this.moment, this.period.time_start, this.period.time_end)
		console.log(this.checkin)
		console.log(typeof this.period.tot_hours_worked)
	}
}

</script>