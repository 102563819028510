<template>
    <div class="login-container">
        <div class="login-wrapper">
            <div class="login-header">
                <div class="avatar">
                    <div class="avatar-image">
                        <img src="/img/icons/logo-info.png" alt="">
                    </div>
                    <div class="avatar-name">Informitalia</div>
                </div>
                <div class="title">
                    Reset Password
                </div>
            </div>
            <div class="login-fields">
                <FormInput v-if="!token" labelText="Username" v-model:value="userData.userName" placeholder="Username" @login="mail"/>
                <FormInput v-if="token" :type="showHidePW" labelText="Password" v-model:value="userData.password" placeholder="Password" icon="visibility" :iconRight="true" @showPassword="showPassword" @login="login"/>
                <FormInput v-if="token" :type="showHidePW" labelText="Conferma password" v-model:value="userData.confirmPassword" placeholder="Conferma password" icon="visibility" :iconRight="true" @showPassword="showPassword" @login="login"/>
                <div v-if="userNameMessage !== false" :class="success">
                    {{ userNameMessage }}
                </div>
                <div v-if="passwordError !== false" class="error">
                    {{ passwordError }}
                </div>
            </div>
            <div class="login-footer">
                <input type="submit" class="submit" @click="validate()" value="Invia">
            </div>
        </div>
    </div>
</template>

<script setup>

import FormInput from '../components/tools/FormInput.vue';
import Password from '../services/passwordService';

</script>

<script>

export default {
    name : 'app-login',
    components : {
        FormInput
    },
    data(){
        return{
            error : false,
            passwordError: false,
            token: false,
            userData : {
                confirmPassword : null,
                password : null,
                userName: null
            },
            userNameMessage: false,
            validUsername: false,
            showHidePW: 'password',
            success: ''
        }
    },
    computed : {
        
    },
    methods : {

        async mail(){

            var data = {
                email : this.userData.userName
            }

            const isUser = await Password.mail(data)

            if(isUser.success == false){
                
                this.userNameMessage = isUser.message
                this.success = 'error'
            }else{
                
                this.userNameMessage = 'Ti è stata inviata una mail'
                this.success = 'success'
            }
        },

        async reset(){

            var data = {
                email : localStorage.getItem('userName'),
                token : localStorage.getItem('resetToken'),
                password : this.userData.password,
                password_confirmation : this.userData.confirmPassword,
            }

            const isPassword = await Password.reset(data)

            if(isPassword.success == false){
                this.passwordError = isPassword.message
            }else{
                this.$router.push('/login')
            }
        },
        
        async validate(){
            if(this.token){
                await this.reset()
            }else{
                await this.mail()
            }
        },
        
        showPassword(type){
            if(type == 'password'){
                this.showHidePW = 'text'
            }else{
                this.showHidePW = 'password'
            }
        }
    },
    mounted(){
        if(localStorage.getItem('resetToken')){
            this.token = true
        }
    }
}

</script>

