import axios from "axios";
import StatusService from "./statusService";

class Password{

    constructor(){

        this.url = `${this.endpoint()}/api/password`

    }

    endpoint = () => {
        
        if(process.env.NODE_ENV == 'production') {
            if(window.location.origin == 'https://informitalia-client.quattrolinee.com') return 'https://informitalia-server.quattrolinee.com';
            else if(window.location.origin == 'https://informitalia-client-test.quattrolinee.com') return 'https://informitalia-server-test.quattrolinee.com';
            else if(window.location.origin == 'https://informitalia-client-test-2.quattrolinee.com' || window.location.origin == 'https://informitalia-app-test.quattrolinee.com') return 'https://informitalia-server-test-2.quattrolinee.com';
            else return 'https://api.informitalia.info';
        }
        else return 'https://informitalia-server-test-2.quattrolinee.com';
    }

    async mail(mail){
        
        return await axios.post(`${this.url}/email`, mail)

        .then(function(response){

            var result = {
                success: response.data.success
            }
            
            console.log(response.data)
            return result
        })
        
        .catch(function (error){

            var result = {
                message : error.response.data.message,
                success : false
            }

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return result
        })
    }

    async reset(data){

        return await axios.post(`${this.url}/reset`, data)

        .then(function(response){

            var result = {
                success: response.data.success
            }
            
            console.log(response.data)
            return result
        })
        
        .catch(function (error){

            var result = {
                message : error.response.data.message,
                success : false
            }

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return result
        })
    }
}

export default new Password()