import axios from "axios";
import LocalStorage from './localStorage'
import StatusService from "./statusService";

class Login{

    constructor(){

        this.url = `${this.endpoint()}/api/loginapp`

    }

    endpoint = () => {
        
        if(process.env.NODE_ENV == 'production') {
            if(window.location.origin == 'https://informitalia-client.quattrolinee.com') return 'https://informitalia-server.quattrolinee.com';
            else if(window.location.origin == 'https://informitalia-client-test.quattrolinee.com') return 'https://informitalia-server-test.quattrolinee.com';
            else if(window.location.origin == 'https://informitalia-client-test-2.quattrolinee.com' || window.location.origin == 'https://informitalia-app-test.quattrolinee.com') return 'https://informitalia-server-test-2.quattrolinee.com';
            else return 'https://api.informitalia.info';
        }
        else return 'https://informitalia-server-test-2.quattrolinee.com';
    }

    async userLogin(userData){

        var result = {
            success :false,
            message: null
        };

        await axios.post(this.url, userData)
        
        .then(function(response){
            LocalStorage.setToken(response.data.data.token)
            
            if(response.data.success == true){
                result.success = true
                result.message = response.data.data.message
                result.user_id = response.data.data.id
            }else{
                result.success = false
                result.message = response.data.message
            }

        })
        
        .catch(function (error){

            result.success = false
            result.message = error.response.message

            console.log(error)
            StatusService.unauthorized(error.response.status)

        })


        return result
    }

}

export default new Login()